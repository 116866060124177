import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { SearchBar } from "./search/SearchBar";
import { Header } from "./ui/Header";
import { Separator } from "~/shadcn/components/ui/separator";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "~/shadcn/components/ui/avatar";
import { postGlobalSearchQuery, postSearchQuery } from "~/queries/search-query";
import {
  GlobalSearchResultsTable,
  SearchResultsTable,
} from "./search/SearchResultsTable";
import { useNavigate } from "react-router-dom";
import { Network } from "~/models/Network";
import { useSearch } from "~/providers/SearchProvider";
import { NetworkSelectorDropdown } from "./search/NetworkSelectorDropdown";
import { cn, csrfToken } from "~/utils/helpers";
import { useApp } from "~/providers/AppProvider";
import { JoinNetworkDialog } from "./search/JoinNetworkDialog";
import { OrganizationNetworkTeaseDialog } from "./search/OrganizationNetworkTeaseDialog";
import { EmailPermissionsContent } from "./ui/EmailPermissions";
import gravatar from "gravatar";
import { MOCK_SEARCH_RESULTS } from "~/providers/MockSearchResults";
import { Crown, Dot, MessageCircle } from "lucide-react";
import { Button } from "~/shadcn/components/ui/button";
import { Dialog } from "~/shadcn/components/ui/dialog";
import { useChat } from "~/providers/ChatProvider";
import { SearchEmptyState } from "./search/SearchEmptyState";
import { RobotAnswer } from "./ui/RobotAnswer";

const triggerChatQuery = async (query, emails) => {
  console.log("query", query);
  const response = await fetch("/spawn_chats", {
    method: "POST",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query, emails }),
  });
  return await response.json();
};

const ChattingIndicator = () => {
  return (
    <div className="text-slate-500 animate-pulse flex items-center gap-[2px]">
      <span className="text-xs font-medium">Chatting</span>
      <div className="w-[14px]">
        <span className="dot-animate text-right"></span>
      </div>
    </div>
  );
};

const ChatSearchResult = ({ searchMutation, chatMutation }) => {
  const { results } = useSearch();
  const {
    setCharacterName,
    setCharacterEmail,
    setActiveConversationId,
    setIsChatOpen,
  } = useChat();

  const [status, setStatus] = useState("");

  const startConversationMutation = useMutation({
    mutationFn: async (character_email: string) => {
      const response = await fetch("/message", {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ character_email: character_email }),
      });

      return response.json();
    },
    onSuccess: (response) => {
      setActiveConversationId(response["conversation_id"]);
      setIsChatOpen(true);
    },
  });

  const finalVerdict = chatMutation.data?.final_verdict;
  const picked = results?.find((r) => r.email == finalVerdict?.email);

  useEffect(() => {
    if (searchMutation.isPending) {
      setStatus("Searching...");
    } else if (searchMutation.isSuccess) {
      setStatus(`Found ${results?.length} results matching your query`);
    }

    if (chatMutation.isPending) {
      const statusMessages = [
        "Asking each agent follow up questions...",
        "Gathering more information...",
        "Analyzing responses...",
        "Processing data...",
        "Finalizing details...",
      ];
      let messageIndex = 0;
      const intervalId = setInterval(() => {
        setStatus(statusMessages[messageIndex]);
        messageIndex = (messageIndex + 1) % statusMessages.length;
      }, 4000);
      return () => clearInterval(intervalId);
    } else if (chatMutation.isSuccess) {
      setStatus("Found the best match");
    }
  }, [
    chatMutation.isPending,
    chatMutation.isSuccess,
    chatMutation.isError,
    searchMutation.isPending,
    searchMutation.isSuccess,
  ]);

  return (
    <div className="flex flex-col gap-2 px-4 my-4 overflow-y-auto mb-20">
      <div className="flex flex-col gap-2">
        <RobotAnswer text={status} isPrimary={!searchMutation.isPending} />

        {results?.map((result) => (
          <div
            key={result.email}
            className="flex text-sm items-center ml-10 relative"
          >
            <Avatar className="h-5 w-5 mr-3">
              <AvatarImage src={gravatar.url(result.email)} alt={result.name} />
              <AvatarFallback>{result.email[0].toUpperCase()}</AvatarFallback>
            </Avatar>
            <Dot className="w-12 h-12 absolute bottom-[-20px] left-[-6px] text-green-500" />

            {result.name && (
              <div className="font-medium whitespace-nowrap">{result.name}</div>
            )}
            {result.name == null && (
              <div
                className={cn(
                  "",
                  result.name != null ? "text-slate-500 ml-2" : "font-medium"
                )}
              >
                {result.email}
              </div>
            )}
            <Crown
              className={cn(
                "ml-2 w-4 h-4 text-yellow-500 transition-opacity duration-300",
                finalVerdict?.email == result.email
                  ? "opacity-100"
                  : "opacity-0"
              )}
              fill="#ffd700"
            />
            <div className="flex-1" />

            {chatMutation.isPending && <ChattingIndicator />}

            <button
              className={cn(
                "flex items-center gap-2 bg-blue-50 rounded-md px-2 py-1 hover:bg-blue-100 transition-opacity duration-300",
                chatMutation.isSuccess ? "opacity-100" : "opacity-0"
              )}
              onClick={() => {
                setCharacterName(result.name);
                setCharacterEmail(result.email);
                startConversationMutation.mutate(result.email);
              }}
            >
              <MessageCircle className="w-4 h-4 text-blue-500" fill="#3b82f6" />
            </button>
          </div>
        ))}

        {chatMutation.isSuccess && finalVerdict && (
          <div className="text-sm ml-10 mt-4 flex flex-col gap-2">
            <div className="font-semibold">Best match</div>
            <div className="flex items-center gap-4 p-3 rounded-lg border border-slate-200 my-2 bg-slate-50">
              <Avatar className="h-8 w-8 border">
                <AvatarImage
                  src={gravatar.url(finalVerdict["email"])}
                  alt={picked?.name}
                />
                <AvatarFallback>M</AvatarFallback>
              </Avatar>
              <div className="flex flex-col gap-1">
                {picked?.name && (
                  <div className="font-semibold">{picked?.name}</div>
                )}
                <div
                  className={cn(
                    "",
                    picked?.name ? "text-slate-500" : "font-medium"
                  )}
                >
                  {finalVerdict["email"]}
                </div>
              </div>
            </div>
            <div className="leading-relaxed">{finalVerdict["reason"]}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export const Search = () => {
  const { user } = useApp();
  const { results, setResults } = useSearch();
  const { query, setQuery, network, setNetwork } = useSearch();
  const [open, setOpen] = useState(false);
  const [initialNetworkSelectionRendered, setInitialNetworkSelectionRendered] =
    useState(false);
  const [showOrganization, setShowOrganization] = useState(false);

  const spawnChatMutation = useMutation({
    mutationFn: async ({
      query,
      emails,
    }: {
      query: string;
      emails: string[];
    }) => {
      return await triggerChatQuery(query, emails);
    },
    onSuccess: (data) => {
      console.log("secondary search results success");
      console.log({ data });
    },
    onError: (error) => {
      console.log(error);
      console.log("error");
    },
  });

  const personalSearchMutation = useMutation({
    mutationFn: (query: string) => {
      setResults(null);
      const data = postSearchQuery(query);
      return data;
    },
    onSuccess: async (data) => {
      setResults(data);
      console.log("First search results");
      console.log({ data });

      await new Promise((resolve) => setTimeout(resolve, 1000));

      spawnChatMutation.mutate({
        query,
        emails: data?.map((r) => r.email) || [],
      });
    },
  });

  const globalSearchMutation = useMutation({
    mutationFn: (query: string) => postGlobalSearchQuery(query),
    onSuccess: (data) => {
      setResults(data);
    },
  });

  useEffect(() => {
    if (initialNetworkSelectionRendered) {
      performSearch(query);
    } else {
      setInitialNetworkSelectionRendered(true);
    }
  }, [network]);

  function performSearch(query: string) {
    if (query === "") {
      return;
    }

    personalSearchMutation.mutate(query);
  }

  function changeNetwork(network: Network) {
    if (network === Network.koah && !user.has_global_access) {
      setOpen(true);
      return;
    }

    setNetwork(network);
  }

  return (
    <div className="flex flex-col w-full">
      <Header>Search People</Header>
      {user.has_email_scope ? (
        <>
          <div className="flex flex-col justify-between items-center w-full">
            <SearchBar
              placeholder={
                network === Network.koah
                  ? "Find people in the global network..."
                  : "Find people in your network..."
              }
              didSpawnChat={() => {
                spawnChatMutation.mutate({
                  query,
                  emails: results?.map((r) => r.email) || [],
                });
              }}
              didSubmit={(query) => {
                setQuery(query);
                performSearch(query);
              }}
            >
              <NetworkSelectorDropdown
                value={network}
                setValue={changeNetwork}
                didSelectOrganization={() => {
                  setShowOrganization(true);
                }}
              />
            </SearchBar>
          </div>
          <Separator />

          {!personalSearchMutation.isPending && results == null ? (
            <SearchEmptyState />
          ) : (
            <ChatSearchResult
              searchMutation={personalSearchMutation}
              chatMutation={spawnChatMutation}
            />
          )}
        </>
      ) : (
        <EmailPermissionsContent />
      )}

      <JoinNetworkDialog open={open} setOpen={setOpen} />
      <OrganizationNetworkTeaseDialog
        open={showOrganization}
        setOpen={setShowOrganization}
      />
    </div>
  );
};
