import React, { useState, useEffect } from "react";
import { Header } from "../ui/Header";
import { Separator } from "~/shadcn/components/ui/separator";
import { SearchBar } from "../search/SearchBar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { postExternalSearchQuery } from "~/queries/search-query";
import { useParams } from "react-router-dom";
import { SearchResultsTable } from "../search/SearchResultsTable";
import { useSearch } from "~/providers/SearchProvider";
import { Asterisk, Dot, Loader2, TriangleAlert } from "lucide-react";
import { toast } from "sonner";
import { ExternalSearchDetailView } from "./ExternalSearchDetailView";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
} from "~/shadcn/components/ui/hover-card";

const SearchErrorState = ({ error }: { error: Error }) => {
  return (
    <div className="flex flex-col w-full h-full items-center justify-center p-4 gap-2">
      <TriangleAlert className="w-6 h-6 text-orange-500 mb-4" />
      <div className="text-xl font-medium">This page does not exist</div>
      <div className="text-gray-500">{error.message}</div>
    </div>
  );
};

export const ExternalSearch = () => {
  const { key } = useParams();
  const { setResults } = useSearch();
  const [openPreview, setOpenPreview] = useState(false);

  const mutation = useMutation({
    mutationFn: ({ query, link_key }: { query: string; link_key: string }) =>
      postExternalSearchQuery(query, link_key),
    onSuccess: (data) => {
      setResults(data);
    },
    onError: (error) => {
      setResults([]);
      toast.error(error.message);
    },
  });

  const { isLoading, error, data } = useQuery({
    queryKey: ["link", key],
    queryFn: async () => {
      const response = await fetch(`/api/links/${key}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch link");
      }
      const result = await response.json();
      return result;
    },
    retry: 1,
  });

  const [selectedPerson, setSelectedPerson] = useState<any>(null);

  return (
    <div className="flex flex-col w-full">
      <Header>
        <div className="flex gap-4 w-full h-full items-center px-5 justify-center bg-gradient-to-b from-blue-300/40 to-white">
          {error ? (
            <div className="w-full">
              <div className="text-black">Invalid link</div>
            </div>
          ) : isLoading ? (
            <div className="w-full">
              <Loader2 className="w-4 h-4 text-blue-600 animate-spin" />
            </div>
          ) : (
            <div className="flex h-full justify-start w-full gap-1 items-center">
              <div className="w-full">
                {data?.title || `${data?.name}'s Network`}
              </div>
              <div className="whitespace-nowrap">{data?.name}</div>
              <Dot className="w-4 h-4" />
              <button
                className="hover:underline"
                onClick={() => {
                  navigator.clipboard.writeText(data?.email);
                  toast.success("Copied email to clipboard");
                }}
              >
                {data?.email}
              </button>
            </div>
          )}
        </div>
      </Header>

      {error ? (
        <SearchErrorState error={error} />
      ) : (
        <>
          <div className="relative mb-10">
            <SearchBar
              sameLine
              placeholder={`Find people in ${data?.name}'s network...`}
              didSubmit={(query) => {
                mutation.mutate({ query: query, link_key: key });
              }}
            />
            <HoverCard>
              <HoverCardTrigger>
                <div className="w-full px-6 absolute bottom-[-20px] left-0 z-1 cursor-help">
                  <div className="rounded-b-lg bg-slate-50 p-2 flex items-center gap-2 shadow-sm overflow-x-scroll">
                    {data?.allowed_topics?.length > 0 && (
                      <>
                        <div className="text-xs text-black whitespace-nowrap">
                          Allowed topics
                        </div>
                        {data?.allowed_topics.map((topic) => (
                          <div
                            key={topic}
                            className="text-xs text-blue-700 px-[8px] py-[2px] rounded-full bg-blue-50 w-fit font-medium whitespace-nowrap"
                          >
                            {topic}
                          </div>
                        ))}
                      </>
                    )}

                    {data?.is_global && (
                      <div className="text-xs text-blue-700 whitespace-nowrap pt-1 flex items-center gap-1">
                        <Asterisk className="w-4 h-4 inline-block" />
                        Full network access
                      </div>
                    )}
                  </div>
                </div>
              </HoverCardTrigger>
              <HoverCardContent>
                <div className="text-sm text-black flex flex-col items-start gap-2">
                  {data?.is_global ? (
                    <>
                      <div className="font-medium">Full network access</div>
                      <div>You have full access to {data?.name}'s network.</div>
                    </>
                  ) : (
                    <>
                      <div className="font-medium">Allowed topics</div>
                      <div>
                        You will only be able to perform searches related to the
                        following topics in {data?.name}'s network.
                      </div>
                    </>
                  )}
                </div>
              </HoverCardContent>
            </HoverCard>
          </div>
          <Separator />

          <SearchResultsTable
            mutation={mutation}
            setSelectedPerson={(person) => {
              setSelectedPerson(person);
              setOpenPreview(true);
            }}
          />

          <ExternalSearchDetailView
            connector_name={data?.name}
            connector_email={data?.email}
            person={selectedPerson}
            open={openPreview}
            setOpen={setOpenPreview}
          />
        </>
      )}
    </div>
  );
};
