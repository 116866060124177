import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

// Image Gallery View like Pinterest
export const Civit = () => {
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["civit", page],
    queryFn: async () => {
      const response = await fetch(`/api/civit?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.json();
    },
  });

  useEffect(() => {
    if (!isFetching) return;
    refetch();
  }, [isFetching]);

  useEffect(() => {
    if (data) {
      setImages((prevImages) => [...data.photos]);
      setIsFetching(false);
    }
  }, [data]);

  return (
    <div className="flex flex-col items-center justify-start w-full">
      <div className="flex flex-row w-full"></div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-8">
        {images.map((image, index) => (
          <div
            key={index}
            className="overflow-hidden rounded-lg shadow-lg w-full max-h-[350px] relative"
          >
            <img
              src={image.url}
              alt={image.title}
              className="w-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-105"
            />
            {image.ad && (
              <div className="absolute bottom-0 left-0 bg-white text-xs py-2 px-2 rounded-b w-full flex justify-between items-center">
                <div className="flex flex-row">
                  <img src={"https://logo.clearbit.com/glossier.com"} className="w-8 h-8" />
                  <div className="ml-2 flex flex-col">
                    <span className="font-semibold">Glossier</span>
                    <span>Promoted</span>
                  </div>
                </div>
                <div
                  className="bg-black text-white px-8 py-2 rounded-md cursor-pointer"
                  onClick={() => window.open("https://www.glossier.com", "_blank")}
                >
                  View Shop
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
