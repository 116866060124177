import React from "react";
import { ArrowUp, Search } from "lucide-react";
import { Button } from "~/shadcn/components/ui/button";
import { useSearch } from "~/providers/SearchProvider";
import { useMutation } from "@tanstack/react-query";
import { csrfToken } from "~/utils/helpers";

export const SearchBar = ({
  placeholder,
  didSubmit,
  didSpawnChat,
  children,
  sameLine = false,
}: {
  placeholder: string;
  didSubmit: (query: string) => void;
  didSpawnChat?: () => void;
  children?: React.ReactNode;
  sameLine?: boolean;
}) => {
  const { query, setQuery } = useSearch();

  const handleSearch = (e: React.FormEvent) => {
    if (query.length === 0) return;
    e.preventDefault();
    didSubmit(query);
  };

  return (
    <form onSubmit={handleSearch} className="flex relative items-center w-full z-50">
      <div className="flex flex-col gap-4 items-start bg-slate-100 rounded-lg m-4 w-full p-2 border border-slate-200">
        <div className="flex items-center w-full pl-2">
          <Search className="w-4 h-4 text-slate-600" />
          <input
            className="w-full border-0 ring-0 focus:ring-0 focus:outline-none bg-transparent"
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={placeholder}
          />
          {sameLine && (
            <Button size="sm" variant="outline" type="submit" disabled={query.length === 0}>
              <ArrowUp strokeWidth={2.4} className={"w-4 h-4"} />
            </Button>
          )}
        </div>

        {!sameLine && (
          <div className="flex items-center w-full justify-between">
            {children ? children : <div className="w-full flex" />}
            <Button size="sm" variant="outline" type="submit" disabled={query.length === 0}>
              <ArrowUp strokeWidth={2.4} className={"w-4 h-4"} />
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};
