import React, { useState } from "react";
import { Header } from "./ui/Header";
import { useApp } from "~/providers/AppProvider";
import { Separator } from "~/shadcn/components/ui/separator";
import { Input } from "~/shadcn/components/ui/input";
import { Button } from "~/shadcn/components/ui/button";
import { ArrowLeft, ChevronRight, CircleAlert, LogOut, Plus } from "lucide-react";
import { Dialog, DialogContent } from "~/shadcn/components/ui/dialog";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { signOut } from "~/queries/sign-out";
import { Switch } from "~/shadcn/components/ui/switch";
import useJoinNetwork from "~/queries/join-network";
import { usePostHog } from "posthog-js/react";

const LogoutModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const posthog = usePostHog();
  const mutation = useMutation({
    mutationFn: signOut,
    onSuccess: () => {
      window.location.reload();
      posthog.reset();
    },
  });

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <div className="flex flex-col items-center gap-6">
          <LogOut className="w-4 h-4 text-gray-500" />

          <div className="flex flex-col items-center gap-2">
            <span className="text-lg font-medium">Log out</span>
            <span className="text-sm text-gray-500 max-w-sm text-center">
              Log out of the current active session and return to the home page
            </span>
          </div>

          <div className="flex flex-col w-full gap-1">
            {/* todo */}
            <Button size="sm" variant="destructive" onClick={() => mutation.mutate()}>
              Log out
            </Button>
            <Button size="sm" variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const AccountDeletionModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { user } = useApp();
  const [emailInput, setEmailInput] = useState("");

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <div className="flex flex-col items-center gap-8">
          <div className="flex flex-col items-center gap-2">
            <CircleAlert className="w-4 h-4 text-red-500" />
            <span className="text-lg font-medium">Delete my account</span>
            <span className="text-sm text-gray-500 max-w-sm text-center">
              This action cannot be undone. This will permanently delete your entire account. All
              data associated with your data will be deleted and will not be recoverable.
            </span>
          </div>

          <div className="flex flex-col w-full items-start gap-1">
            <span className="text-sm">Type your email to confirm account deletion</span>
            <Input
              placeholder={user?.email}
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
            />
          </div>

          <div className="flex flex-col w-full gap-1">
            <Button size="sm" variant="destructive" disabled={emailInput !== user?.email}>
              Permanently delete my account
            </Button>

            <Button size="sm" variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const ProfileView = () => {
  const { user } = useApp();
  const [confirmLogout, setConfirmLogout] = useState(false);
  const [confirmAccountDeletion, setConfirmAccountDeletion] = useState(false);
  const { mutate: joinNetwork } = useJoinNetwork();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full">
      <Header>
        <div className="flex items-center justify-start w-full px-2">
          <Button variant="ghost" onClick={() => navigate(-1)}>
            <ArrowLeft className="w-4 h-4" />
          </Button>

          <span className="px-4">Profile</span>
        </div>
      </Header>

      <div className="flex flex-col gap-6 px-6 py-6">
        <span className="text-lg font-medium">Account</span>

        <div className="flex flex-col items-start gap-2 text-sm">
          <span>Email</span>
          <Input value={user?.email} className="w-fit text-sm" disabled />
        </div>

        <div className="flex items-start justify-between gap-2 text-sm w-full">
          <div className="flex flex-col items-start gap-1">
            <span>KOAH Network</span>
            <span className="text-gray-500">Access the entire KOAH network</span>
          </div>
          <Switch
            checked={user.has_global_access}
            onCheckedChange={(checked) => {
              if (checked) {
                joinNetwork(true);
              } else {
                joinNetwork(false);
              }
            }}
          />
        </div>

        {/* <div className="flex flex-col items-start text-sm gap-2">
          <span>Profile URL</span>
          <div className="flex items-center border border-slate-200 rounded-md p-2">
            <div className="text-slate-600">koah.ai/</div>
            <input
              value={"mkchoi212"}
              className="p-0 text-start text-sm border-0 bg-transparent ring-0 focus:ring-0 focus:ring-transparent"
            />
          </div>
          <Button className="mt-4" size="sm">
            Update
          </Button>
        </div> */}

        <Separator />

        <div className="flex flex-col gap-6">
          <span className="text-lg font-medium">Integrations</span>

          <div className="flex flex-col items-start gap-1 text-sm">
            <span>{user?.email}</span>
            <span className="text-sm text-gray-400">
              {user?.email ? "Connected" : "Not connected"}
            </span>
          </div>

          <Button size="sm" variant="outline">
            <Plus className="mr-2 w-4 h-4" />
            Connect
          </Button>
        </div>

        <Separator />

        <div className="flex flex-col gap-6">
          <span className="text-lg font-medium">Support</span>

          <button
            className="flex items-center justify-between text-sm"
            onClick={() => {
              window.open("mailto:team@koahlabs.com", "_blank");
            }}
          >
            <div className="flex flex-col items-start gap-1">
              <span>Contact us</span>
              <span className="text-gray-500">Get in touch with the KOAH team</span>
            </div>
            <ChevronRight className="w-4 h-4 text-gray-500" />
          </button>

          <button
            className="flex items-center justify-between text-sm"
            onClick={() => setConfirmLogout(true)}
          >
            <div className="flex flex-col items-start gap-1">
              <span>Log out</span>
              <span className="text-gray-500">Log out of the current active session</span>
            </div>
            <ChevronRight className="w-4 h-4 text-gray-500" />
          </button>

          {/*           <button
            className="flex items-center justify-between text-sm"
            onClick={() => setConfirmAccountDeletion(true)}
          >
            <div className="flex flex-col items-start gap-2">
              <span className="text-red-500">Delete my account</span>
              <span className="text-gray-500">
                Permanently delete your account and all associated data
              </span>
            </div>
            <ChevronRight className="w-4 h-4 text-gray-500" />
          </button> */}
        </div>
      </div>

      <LogoutModal open={confirmLogout} onClose={() => setConfirmLogout(false)} />

      <AccountDeletionModal
        open={confirmAccountDeletion}
        onClose={() => setConfirmAccountDeletion(false)}
      />
    </div>
  );
};
