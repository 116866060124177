import React from "react";
import { FileText, GlassesIcon, Languages, School } from "lucide-react";
// @ts-ignore
import ApolloLogo from "~/assets/images/apollo.png";
// @ts-ignore
import PartyCityLogo from "~/assets/images/party-city.png";
import { ChatOption, useChatMode } from "~/providers/ChatModeProvider";

const ALL_EMPTY_ACTIONS = [
  {
    title: "Brand Awareness",
    description: "Boost your brand's visibility",
    icon: <GlassesIcon className="w-4 h-4" strokeWidth={1.8} color="purple" />,
  },
  {
    title: "Get school advice",
    icon: <School className="w-4 h-4" strokeWidth={1.8} color="green" />,
  },
  {
    title: "Summarize a document",
    icon: <FileText className="w-4 h-4" strokeWidth={1.8} color="orange" />,
  },
  {
    title: "Translate a document",
    icon: <Languages className="w-4 h-4" strokeWidth={1.8} color="blue" />,
  },
  {
    title: "How to find leads with Apollo",
    icon: (
      <img src={ApolloLogo} className="w-8 rounded-md h-fit border shadow-sm" />
    ),
    isAd: true,
  },
  {
    title: "Halloween costume ideas with PartyCity",
    icon: (
      <img
        src={PartyCityLogo}
        className="w-8 rounded-md h-fit border shadow-sm"
      />
    ),
    isAd: true,
  },
];

export const ChatEmptyView = () => {
  const { adType } = useChatMode();
  const filteredActions =
    adType !== ChatOption.NO_ADS
      ? ALL_EMPTY_ACTIONS.slice(2)
      : ALL_EMPTY_ACTIONS.filter((item) => !item.isAd);

  return (
    <div className="w-full h-fit text-center flex flex-col gap-4 px-24 items-center justify-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <span className="text-2xl font-medium">What can I help with?</span>
      <div className="grid grid-cols-2 gap-4 mt-4 max-w-2xl">
        {filteredActions.map((item, index) => (
          <div
            key={index}
            className="bg-white p-4 rounded-lg cursor-pointer border flex flex-col items-start gap-1"
          >
            {item.isAd && (
              <div className="mb-2 flex items-center gap-2 justify-between w-full">
                {item.icon}

                {item.isAd && (
                  <span className="text-xs text-muted-foreground p-1 px-2 bg-slate-100 rounded-full">
                    Ad
                  </span>
                )}
              </div>
            )}

            <div className="flex items-start gap-2">
              {!item.isAd && item.icon}
              <span className="font-semibold text-left text-sm">
                {item.title}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
