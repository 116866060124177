import React, { useState } from "react";
import { Header } from "../ui/Header";
import { Link, Plus } from "lucide-react";
import { Button } from "~/shadcn/components/ui/button";
import { useFetchAllLinks } from "~/queries/fetch-links";
import { LinkRow } from "./LinkRow";
import { CreateLinkModal } from "./CreateLinkModal";
import { Separator } from "~/shadcn/components/ui/separator";

const ShareEmptyState = ({
  didClickCreate,
}: {
  didClickCreate: () => void;
}) => {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="flex flex-col items-start text-sm gap-4 max-w-md">
        <div className="rounded-lg shadow p-2 border border-blue-500 bg-gradient-to-b from-blue-500/20 to-blue-500/10">
          <Link className="w-4 h-4 text-blue-900" />
        </div>
        <p className="text-xl font-semibold">Create your personal link</p>
        <p className="text-slate-700 mb-4">
          Let others search your network by sending them your personal link.
        </p>
        <Button onClick={didClickCreate} className="w-20 animate-in">
          Create
        </Button>
      </div>
    </div>
  );
};

export const ShareView = () => {
  const { data: links } = useFetchAllLinks();
  const [open, setOpen] = useState(false);

  function didClickCreate() {
    setOpen(true);
  }

  return (
    <div className="flex flex-col w-full">
      <Header>
        <div className="flex flex-row text-left items-center w-full px-6">
          <span className="w-full">My Links</span>

          <button
            className="flex flex-row items-center gap-2 bg-black rounded-lg p-2 w-20"
            onClick={didClickCreate}
          >
            <>
              <Plus className="w-4 h-4 text-white" strokeWidth={2.5} />
              <span className="text-white text-xs">New</span>
            </>
          </button>
        </div>
      </Header>

      {links?.length === 0 && (
        <ShareEmptyState didClickCreate={didClickCreate} />
      )}

      <div className="flex flex-col gap-4 overflow-y-auto px-5 mt-4">
        {links?.map((link, idx) => (
          <>
            {idx != 0 && <Separator className="w-full" />}
            <LinkRow key={link.id} link={link} />
          </>
        ))}
      </div>

      <CreateLinkModal open={open} setOpen={setOpen} />
    </div>
  );
};
