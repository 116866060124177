import React, { useState } from "react";
import { Separator } from "~/shadcn/components/ui/separator";
import { SearchSortDropdown } from "./SearchSortDropdown";
import { ExternalPersonCell, PersonCell } from "../PersonCell";
import { SearchEmptyState } from "./SearchEmptyState";
import { useSearch } from "~/providers/SearchProvider";
import { Skeleton } from "~/shadcn/components/ui/skeleton";
import { ExternalSearchDetailView } from "../external_search/ExternalSearchDetailView";
import { usePostHog } from "posthog-js/react";

const SearchFooterLabel = ({ text }) => {
  return <div className="text-sm font-medium text-gray-700">{text}</div>;
};

const SearchSkeletonTable = () => {
  return (
    <div className="flex flex-col items-start p-4 w-full gap-6 overflow-y-auto">
      {Array.from({ length: 10 }).map((_, index) => (
        <div key={index} className="flex flex-col items-start gap-2 w-full">
          <Skeleton className="h-4 w-full max-w-[380px]" />
          <Skeleton className="h-4 w-2/3 max-w-[250px]" />
        </div>
      ))}
    </div>
  );
};

export const GlobalSearchResultsTable = ({ mutation }) => {
  const { results } = useSearch();
  const [openPreview, setOpenPreview] = useState(false);
  const [connectorName, setConnectorName] = useState("");
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [connectorEmail, setConnectorEmail] = useState("");
  const posthog = usePostHog();

  return (
    <>
      <div className="flex justify-between items-center pl-6 py-2">
        {mutation.isPending ? (
          <SearchFooterLabel text="Searching..." />
        ) : mutation.isError ? (
          <SearchFooterLabel text={`Error ${mutation.error.message}`} />
        ) : results != null ? (
          <SearchFooterLabel text={`${results.length} results`} />
        ) : (
          <div className="flew grow" />
        )}

        <SearchSortDropdown />
      </div>

      {mutation.isPending && <SearchSkeletonTable />}

      {!mutation.isPending &&
        (results == null ? (
          <SearchEmptyState />
        ) : (
          <div className="flex flex-col max-w-[800px] h-full overflow-y-auto">
            {results.map((response, index) => (
              <div
                key={index}
                className="flex flex-col cursor-pointer"
                onClick={() => {
                  setSelectedPerson(response);
                  setConnectorName(response.owner_name);
                  setConnectorEmail(response.owner_email);
                  setOpenPreview(true);

                  posthog?.capture("click on person", {
                    email: response?.email,
                    name: response?.name,
                  });
                }}
              >
                <ExternalPersonCell response={response} />
                <Separator />
              </div>
            ))}
          </div>
        ))}

      <ExternalSearchDetailView
        connector_name={connectorName}
        connector_email={connectorEmail}
        person={selectedPerson}
        open={openPreview}
        setOpen={setOpenPreview}
      />
    </>
  );
};

export const SearchResultsTable = ({ mutation, setSelectedPerson }) => {
  const { results } = useSearch();
  const posthog = usePostHog();

  return (
    <>
      <div className="flex justify-between items-center pl-6 py-2">
        {mutation.isPending ? (
          <SearchFooterLabel text="Searching..." />
        ) : mutation.isError ? (
          <SearchFooterLabel text={`Error: ${mutation.error.message}`} />
        ) : results != null ? (
          <SearchFooterLabel text={`${results.length} results`} />
        ) : (
          <div className="flew grow" />
        )}

        <SearchSortDropdown />
      </div>

      {mutation.isPending && <SearchSkeletonTable />}

      {!mutation.isPending &&
        (results == null ? (
          <SearchEmptyState />
        ) : (
          <div className="flex flex-col max-w-[800px] h-full overflow-y-auto">
            {results.map((response, index) => (
              <div
                key={index}
                className="flex flex-col cursor-pointer"
                onClick={() => {
                  setSelectedPerson(response);
                  posthog?.capture("click on person", {
                    email: response?.email,
                    name: response?.name,
                  });
                }}
              >
                <PersonCell response={response} />
                <Separator />
              </div>
            ))}
          </div>
        ))}
    </>
  );
};
