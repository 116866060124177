import React, { useState, useRef, useEffect } from "react";
import { Button } from "~/shadcn/components/ui/button";
import { Input } from "~/shadcn/components/ui/input";
import { ScrollArea } from "~/shadcn/components/ui/scroll-area";
import { Avatar, AvatarFallback, AvatarImage } from "~/shadcn/components/ui/avatar";
import { ChevronDown, ChevronUp, SendIcon, X } from "lucide-react";
import { useChat } from "~/providers/ChatProvider";
import { useMutation, useQuery } from "@tanstack/react-query";
import { csrfToken } from "~/utils/helpers";
import { queryClient } from "./App";
import gravatar from "gravatar";

interface Message {
  id: number;
  content: string;
  sender: "user" | "other";
}

export default function CollapsibleChat() {
  const {
    activeConversationId,
    isChatOpen,
    setIsChatOpen,
    characterEmail,
    characterName,
    setActiveConversationId,
  } = useChat();
  const [inputMessage, setInputMessage] = useState("");

  const { isLoading, error, data } = useQuery({
    queryKey: ["conversation", activeConversationId],
    queryFn: async () => {
      const response = await fetch(`/chat?conversation_id=${activeConversationId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch conversation history");
      }
      const result = await response.json();
      return result.messages;
    },
    retry: 1,
  });

  const sendMessageMutation = useMutation({
    mutationFn: async ({
      message,
      character_email,
    }: {
      message: string;
      character_email: string;
    }) => {
      const response = await fetch("/message", {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: message,
          character_email: character_email,
        }),
      });

      return response.json();
    },
    onMutate: async ({
      message,
      character_email,
    }: {
      message: string;
      character_email: string;
    }) => {
      await queryClient.cancelQueries({
        queryKey: ["conversation", activeConversationId],
      });

      const previousMessages = queryClient.getQueryData<Message[]>([
        "conversation",
        activeConversationId,
      ]);

      const optimisticMessage: Message = {
        id: Math.random() * 10000000,
        content: message,
        sender: "user",
      };

      queryClient.setQueryData<Message[]>(["conversation", activeConversationId], (old) => [
        ...(old || []),
        optimisticMessage,
      ]);

      setInputMessage("");

      return { previousMessages, optimisticMessage };
    },
    onError: (err, newMessageContent, context) => {
      queryClient.setQueryData(["conversation", activeConversationId], context?.previousMessages);
      alert("Failed to send message. Please try again.");
    },
    onSuccess: (serverMessage, newMessageContent, context) => {
      console.log({ serverMessage });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["conversation", activeConversationId],
      });
    },
  });

  const messages = (data || []).map((message) => ({
    id: message.id,
    content: message.content,
    sender: message.sender,
  }));

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const handleSendMessage = () => {
    if (inputMessage.trim()) {
      sendMessageMutation.mutate({ message: inputMessage, character_email: characterEmail });
      setInputMessage("");
    }
  };

  const toggleExpand = () => {
    setIsChatOpen(!isChatOpen);
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div
      className={`fixed bottom-4 left-8 w-80 bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 ease-in-out ${
        isChatOpen ? "h-[28rem]" : "h-14"
      }`}
    >
      {/* Chat Header */}
      <div className="bg-primary text-primary-foreground p-3 flex items-center justify-between">
        <div className="flex items-center">
          <Avatar className="h-8 w-8 mr-2">
            <AvatarImage src={gravatar.url(characterEmail)} alt="John Doe" />
            <AvatarFallback>JD</AvatarFallback>
          </Avatar>
          <h3 className="font-semibold">{characterName || characterEmail}</h3>
        </div>
        <div className="flex items-center space-x-2">
          <Button variant="ghost" size="icon" onClick={toggleExpand} className="h-6 w-6">
            {isChatOpen ? <ChevronDown className="h-4 w-4" /> : <ChevronUp className="h-4 w-4" />}
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className="h-6 w-6"
            onClick={() => setActiveConversationId(null)}
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
      </div>

      {/* Chat Messages */}
      {isChatOpen && (
        <ScrollArea className="h-[calc(28rem-6rem)] p-4">
          {messages.map((message) => (
            <div
              key={message.id}
              className={`mb-4 ${message.sender === "user" ? "text-right" : "text-left"}`}
            >
              <div
                className={`inline-block p-2 rounded-lg ${
                  message.sender === "user" ? "bg-primary text-primary-foreground" : "bg-secondary"
                }`}
              >
                {message.content}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </ScrollArea>
      )}

      {/* Message Input */}
      {isChatOpen && (
        <div className="absolute bottom-0 left-0 right-0 p-3 border-t bg-background">
          <div className="flex items-center">
            <Input
              className="flex-1 mr-2"
              placeholder="Type a message..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
            />
            <Button variant="ghost" size="icon" onClick={handleSendMessage}>
              <SendIcon className="h-5 w-5" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
