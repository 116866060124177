import { Dot, Handshake, MessageCircle } from "lucide-react";
import React from "react";
import { cn, csrfToken } from "~/utils/helpers";
import { ResultEllipsisButton } from "./ResultEllipsisButton";
// @ts-ignore
import HandwaveIcon from "~/assets/images/handwave.svg?react";
import { Button } from "~/shadcn/components/ui/button";
import { useChat } from "~/providers/ChatProvider";
import { useMutation, useQuery } from "@tanstack/react-query";

export interface PersonResultProps {
  response: {
    name?: string;
    email: string;
    topics: string[];
    explanation: string;
    is_relevant: boolean;
  };
}

export const ExternalPersonCell = ({ response }) => {
  const displayName =
    response.name || response.email.charAt(0).toUpperCase() + response.email.split("@")[0].slice(1);
  const owner_name = response.owner_name;
  const owner_email = response.owner_email;

  return (
    <div className="flex justify-between pl-4 py-6 pr-2 hover:bg-slate-100 transition-colors">
      <div className="flex flex-col justify-start items-center px-2 text-sm gap-2">
        <div className="flex flex-col items-start w-full">
          <div className="font-bold">{displayName}</div>
        </div>

        {response.explanation && <div className="text-gray-500">{response.explanation}</div>}

        <div className="flex gap-1 justify-start items-center text-gray-500 text-xs w-full">
          <HandwaveIcon className="w-[14px] h-[14px]" />
          <div className="font-medium">{owner_name || owner_email}</div>
          <span> can make the intro</span>
        </div>
      </div>

      <ResultEllipsisButton />
    </div>
  );
};

export const PersonCell = ({ response }: PersonResultProps) => {
  const name = response.name;
  const email = response.email;
  const { setActiveConversationId, setIsChatOpen, setCharacterEmail, setCharacterName } = useChat();

  const startConversationMutation = useMutation({
    mutationFn: async (character_email: string) => {
      const response = await fetch("/message", {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ character_email: character_email }),
      });

      return response.json();
    },
    onSuccess: (response) => {
      setCharacterName(name || email);
      setCharacterEmail(email);
      setActiveConversationId(response["conversation_id"]);
      setIsChatOpen(true);
    },
  });

  return (
    <div className="flex justify-between pl-4 py-6 pr-2 transition-colors">
      <div className="flex flex-col justify-center items-center px-2 text-sm gap-1">
        <div className="flex items-center w-full">
          {name && (
            <>
              <div className="font-bold">{name}</div>
              <Dot className="w-4 h-4 text-gray-500" />
            </>
          )}

          <div className={cn("", name ? "text-gray-500" : "text-black font-bold")}>{email}</div>
        </div>

        <div className="text-gray-500 w-full">{response.explanation}</div>
      </div>

      <div>
        <Button
          variant="ghost"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            startConversationMutation.mutate(email);
          }}
        >
          <MessageCircle className="w-4 h-4" />
        </Button>
        <ResultEllipsisButton />
      </div>
    </div>
  );
};
