import React from "react";
import { TriangleAlert } from "lucide-react";
import { Button } from "~/shadcn/components/ui/button";
import { csrfToken } from "~/utils/helpers";

export const EmailPermissionsContent = () => {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <div className="flex flex-col items-start gap-2 border border-yellow-400 bg-yellow-100 rounded-lg p-4 text-sm justify-center  max-w-md">
          <p className="text-yellow-700 font-semibold flex items-center gap-2">
            <TriangleAlert className="w-4 h-4 inline-block" /> Heads up
          </p>
          <p className="text-black">
            This feature requires Gmail read access. Please change your permissions and try again.
          </p>
  
          <form
            action={`${window.location.origin}/users/auth/google_oauth2?scope=gmail.readonly`}
            method="post"
          >
            <input type="hidden" name="authenticity_token" value={csrfToken} />
            <Button size="sm" type="submit" className="mt-6">
              Update Google Permissions
            </Button>
          </form>
        </div>
      </div>
    );
  };
  