import React, { useState } from "react";
import InputBox from "./InputBox";
import RootBubble, { BubbleType, Speaker } from "./RootBubbleView";
import { Message } from "./models/Message";
import { UserBubble } from "./UserBubble";
import CopyMessageButton from "./CopyMessageButton";
import ChatAdModeSelector from "../ChatAdModeSelector";
import { toast } from "sonner";
// @ts-ignore
import Logo from "~/assets/images/logo.svg?react";
import AdBubble from "./AdBubble";
import { ChatEmptyView } from "./ChatEmptyView";
import { useMutation } from "@tanstack/react-query";
import { csrfToken } from "~/utils/helpers";
import { useChatMode } from "~/providers/ChatModeProvider";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

function AssistantBubble({
  type,
  message,
}: {
  type?: BubbleType;
  message: Message;
}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <RootBubble
      type={type}
      speaker={Speaker.ASSISTANT}
      setIsHovered={setIsHovered}
    >
      <div className="flex items-center w-full gap-2">
        <Logo className="w-8 h-8 border rounded-lg shadow-md bg-white" />
        <span className="w-full">
          <Markdown rehypePlugins={[rehypeRaw]}>{message.content}</Markdown>
        </span>
      </div>

      <CopyMessageButton message={message.content} isHovered={isHovered} />
    </RootBubble>
  );
}

function AssistantBubbleRenderer({
  type,
  message,
}: {
  type: BubbleType;
  message: Message;
}) {
  return <AssistantBubble message={message} />;
}

export const ChatStuff = () => {
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const { adType } = useChatMode();

  const { mutate, isPending } = useMutation({
    mutationFn: async ({
      message,
      history,
      ad_type,
    }: {
      message: string;
      history: Message[];
      ad_type: string;
    }) => {
      const response = await fetch("/ad_chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          message,
          history,
          ad_type: adType.toLowerCase().replace(" ", "_"),
        }),
      });

      return response.json();
    },
    onSuccess: (data) => {
      setMessages(data.history);
    },
    onError: (error) => {
      toast.error("Failed to send message. Please try again.");
    },
  });

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    mutate({ message, history: messages, ad_type: adType });
    setMessages([
      ...messages,
      { id: Math.random().toString(), role: "user", content: message },
    ]);
    setMessage("");
  }

  return (
    <>
      <div className="absolute top-4 left-4">
        <ChatAdModeSelector />
      </div>

      <div className="flex flex-col items-center justify-center w-full h-screen">
        <div className="flex flex-col w-full h-full gap-4 overflow-y-scroll pb-[300px]">
          <div className="flex flex-col gap-4 min-w-[700px] max-w-[700px] mx-auto">
            {messages.map((message, idx) => {
              const bubbleType =
                idx === 0
                  ? BubbleType.FIRST
                  : idx === messages.length - 1
                  ? BubbleType.LAST
                  : null;

              if (message.role === "assistant") {
                return (
                  <AssistantBubbleRenderer
                    key={message.id}
                    type={bubbleType}
                    message={message}
                  />
                );
              } else {
                return (
                  <UserBubble
                    key={message.id}
                    type={bubbleType}
                    message={message}
                  />
                );
              }
            })}
          </div>
        </div>

        <InputBox
          message={message}
          onChange={setMessage}
          onSubmit={handleSubmit}
        />
      </div>

      {messages.length === 0 && <ChatEmptyView />}
    </>
  );
};
