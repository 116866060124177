import React, { useState } from "react";
import { Check, ChevronDown, Flame, Target } from "lucide-react";
import { cn } from "~/utils/helpers";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "~/shadcn/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/shadcn/components/ui/popover";

const templates = [
  {
    value: "warm intro",
    label: "Warm Intro",
    icon: <Flame className="w-4 h-4 text-red-400" />,
  },
  {
    value: "direct outreach",
    label: "Direct Outreach",
    icon: <Target className="w-4 h-4 text-purple-400" />,
  },
];

export function EmailTemplateDropdown({
  value,
  setValue,
}: {
  value: string;
  setValue: (value: string) => void;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button className="text-sm border rounded-full px-[8px] py-[6px] flex items-center hover:bg-slate-100 transition-colors">
          {templates.find((template) => template.value === value)?.icon}
          <span className="text-xs font-medium ml-2">
            {templates.find((template) => template.value === value)?.label}
          </span>
          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-1">
        <Command>
          <CommandList>
            <CommandGroup>
              {templates.map((template) => (
                <CommandItem
                  key={template.value}
                  value={template.value}
                  onSelect={(currentValue) => {
                    setValue(currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === template.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {template.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
