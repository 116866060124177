import React, { createContext, useContext, useState } from "react";

interface ChatContextType {
  activeConversationId: string;
  setActiveConversationId: (conversationId: string) => void;
  characterName: string;
  setCharacterName: (email: string) => void;
  characterEmail: string;
  setCharacterEmail: (email: string) => void;
  isChatOpen: boolean;
  setIsChatOpen: (isOpen: boolean) => void;
}
const ChatContext = createContext<ChatContextType | null>(null);

export const ChatProvider = ({ children }: { children: React.ReactNode }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [activeConversationId, setActiveConversationId] = useState<string | null>(null);
  const [characterEmail, setCharacterEmail] = useState<string | null>(null);
  const [characterName, setCharacterName] = useState<string | null>(null);
  return (
    <ChatContext.Provider
      value={{
        activeConversationId,
        setActiveConversationId,
        characterName,
        setCharacterName,
        characterEmail,
        setCharacterEmail,
        isChatOpen,
        setIsChatOpen,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
