import React from "react";
import { Button } from "~/shadcn/components/ui/button";
import { Dialog, DialogContent } from "~/shadcn/components/ui/dialog";
import {
  Building2,
  Flower,
  Globe2,
  HandHeart,
  HandshakeIcon,
  Loader2,
  Lock,
} from "lucide-react";
// @ts-ignore
import Logo from "~/assets/images/logo.svg?react";
import { FeatureRow } from "../ui/FeatureRow";

export const OrganizationNetworkTeaseDialog = ({ open, setOpen }) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <div className="flex flex-col gap-4 items-center">
          <div className="bg-slate-100 rounded-lg shadow p-1">
            <Logo className="w-10 h-10 rounded-lg shadow bg-gradient-to-b from-white to-slate-100" />
          </div>
          <div className="text-xl font-semibold">
            Unlock your organization's network
          </div>

          <div className="text-sm text-slate-600 text-center">
            Bring KOAH to your organization and unlock the full power of your
            organization's network.
          </div>

          <div className="flex flex-col gap-4 bg-slate-400/10 rounded-lg p-4 text-sm text-slate-700 w-full my-2">
            <FeatureRow
              icon={<Building2 className="w-4 h-4" />}
              title="Internal network"
              description="Search through your organization's internal network"
              color="bg-green-500"
            />
            <FeatureRow
              icon={<HandshakeIcon className="w-4 h-4" />}
              title="Facilitate connections"
              description="Help your employees find the right person within your organization in seconds"
              color="bg-purple-500"
            />
            <FeatureRow
              icon={<Lock className="w-4 h-4" />}
              title="Enterprise Privacy"
              description="Your organization's data is private. We will not share it with anyone outside your organization."
              color="bg-black"
            />
          </div>

          <Button
            className="w-full text-white"
            onClick={() =>
              window.open("https://cal.com/mike-choi/30min", "_blank")
            }
          >
            Talk to Sales
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
