import React, { useState } from "react";
import { Button } from "~/shadcn/components/ui/button";
import { Dialog, DialogContent } from "~/shadcn/components/ui/dialog";
import { Loader2 } from "lucide-react";
import TokenInputTextfield, { Pill } from "./TokenInputTextfield";
import { Switch } from "~/shadcn/components/ui/switch";
import useCreateLink from "~/queries/create-link";
import { toast } from "sonner";
import { Input } from "~/shadcn/components/ui/input";

export const CreateLinkModal = ({ open, setOpen }) => {
  const [shareEntireNetwork, setShareEntireNetwork] = useState(true);
  const [allowedTopics, setAllowedTopics] = useState<Pill[]>([]);
  const [title, setTitle] = useState("");
  const { mutate: createLink, isPending } = useCreateLink(() => {
    toast.success("Link created");
    setOpen(false);
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <div className="flex flex-col gap-6 items-start">
          <div className="flex flex-col gap-2">
            <div className="text-xl font-semibold">Create a public page</div>
            <div className="text-sm text-slate-600 mb-2">
              Share your network with others. Define which part of the network
              you want to share.
            </div>
          </div>

          <div className="flex flex-col gap-2 w-full">
            <div className="text-sm font-semibold text-slate-800">
              Link Title
            </div>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Investors in my network..."
            />
          </div>

          <div className="flex flex-col gap-2 w-full">
            <div className="text-sm font-semibold text-slate-800">
              Allowed Topics
            </div>
            <TokenInputTextfield
              pills={allowedTopics}
              setPills={setAllowedTopics}
              disabled={shareEntireNetwork}
            />
          </div>

          <div className="flex justify-between items-center w-full">
            <div className="flex flex-col gap-1 items-start">
              <div className="text-sm font-semibold text-slate-800">
                Share entire network
              </div>
              <div className="text-xs text-slate-600">
                People with this link will be able to perform any kind of query.
              </div>
            </div>
            <Switch
              checked={shareEntireNetwork}
              onCheckedChange={setShareEntireNetwork}
            />
          </div>

          <div className="flex flex-row gap-2 w-full mt-2">
            <div className="flex-1" />
            <Button variant="outline" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              className="text-white"
              disabled={isPending}
              onClick={() => {
                createLink({
                  title,
                  is_global: shareEntireNetwork,
                  allowed_topics: allowedTopics.map((pill) => pill.text),
                });
              }}
            >
              {isPending ? (
                <Loader2 className="w-4 h-4 animate-spin" />
              ) : (
                "Create"
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
