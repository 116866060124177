import React, { useState } from "react";
import MediumSelector from "./chat/MediumSelector";
import { Civit } from "../Civit";
import { ChatStuff } from "./chat/Chat";

export default function Gallery() {
  const [selectedTab, setSelectedTab] = useState<string>("chat");

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-[#FDFAFA]">
      <div className="flex flex-col items-center absolute top-4 left-0 right-0">
        <MediumSelector
          selected={selectedTab}
          onChange={(selected) => setSelectedTab(selected)}
        />
      </div>

      {selectedTab === "chat" && <ChatStuff />}

      {selectedTab === "image" && <Civit />}
    </div>
  );
}
