import React, { useState } from "react";
import { Asterisk, Building, Check } from "lucide-react";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "~/shadcn/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/shadcn/components/ui/popover";
import { Network } from "~/models/Network";
import { ChevronDown, Globe, User } from "lucide-react";
import { cn } from "~/utils/helpers";
import { useApp } from "~/providers/AppProvider";

const NetworkSelectorTrigger = ({ value }: { value: string }) => {
  return (
    <div className="px-[8px] py-[6px] font-medium text-xs rounded-full border flex items-center bg-white hover:bg-slate-50 transition-colors">
      {value === "all" ? (
        <Asterisk className="w-4 h-4 mr-2 text-slate-500" />
      ) : value === "personal" ? (
        <User className="w-4 h-4 mr-2 text-slate-500" />
      ) : (
        <Globe className="w-4 h-4 mr-2 text-green-500" />
      )}

      {value === "all"
        ? "All Networks"
        : value === "personal"
        ? "Personal"
        : "KOAH Network"}

      <ChevronDown className="w-4 h-4 ml-2" />
    </div>
  );
};

const networkOptions = [
  {
    key: Network.personal,
    value: Network.personal,
    label: "Personal",
    icon: <User className="w-4 h-4 mr-2 text-slate-500" />,
  },
  {
    key: Network.organization,
    value: Network.organization,
    label: "Your Organization",
    icon: <Building className="w-4 h-4 mr-2 text-purple-500" />,
  },
  {
    key: Network.koah,
    value: Network.koah,
    label: "KOAH Global",
    icon: <Globe className="w-4 h-4 mr-2 text-green-500" />,
  },
];

export const NetworkSelectorDropdown = ({
  value,
  setValue,
  didSelectOrganization,
}: {
  value: Network;
  setValue: (network: Network) => void;
  didSelectOrganization: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const { user } = useApp();

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <NetworkSelectorTrigger value={value} />
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-1">
        <Command>
          <CommandList>
            <CommandGroup className="gap-2 flex flex-col">
              <div className="flex flex-col text-sm gap-1 p-2 mb-2">
                <div className="font-medium">Choose network</div>
                <div className="font-light">
                  Select the networks you want to search within
                </div>
              </div>
              {networkOptions.map((option) => (
                <CommandItem
                  key={option.key}
                  value={option.value}
                  onSelect={(currentValue) => {
                    if (currentValue === Network.organization) {
                      setOpen(false);
                      didSelectOrganization();
                      return;
                    }

                    setValue(currentValue as Network);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === option.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {option.icon}
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
