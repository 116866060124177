import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
// @ts-ignore
import Logo from "~/assets/images/dark-logo.svg?react";
import { cn } from "~/utils/helpers";

export const RobotAnswer = ({ text, isPrimary = true }) => {
    return (
      <div className="flex items-center gap-2">
        <Logo className="w-8 h-8 border rounded-full" />
        <div
          className={cn(
            'text-sm relative overflow-hidden',
            isPrimary ? '' : 'text-slate-700'
          )}
          style={{ position: 'relative', overflow: 'hidden' }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={text}
              initial={{ y: '100%' }}
              animate={{ y: '0%' }}
              exit={{ y: '-100%' }}
              transition={{ duration: 0.3 }}
            >
              {text}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    );
  };
  