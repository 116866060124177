import React, { createContext, useState, useContext } from "react";

interface ChatModeContextType {
  isSuffixEnabled: boolean;
  setIsSuffixEnabled: (isEnabled: boolean) => void;
  isBannerEnabled: boolean;
  setIsBannerEnabled: (isEnabled: boolean) => void;
  adType: ChatOption;
  setAdType: (adType: ChatOption) => void;
}

const ChatModeContext = createContext<ChatModeContextType | null>(null);

export enum ChatOption {
  NO_ADS = "No Ads",
  SUFFIX_ADS = "Suffix Ads",
  INLINE_DETECTION = "Inline Detection",
  INLINE_MODIFICATION = "Inline Modification",
  RECOMMENDED_QUESTIONS = "Recommended Questions",
}

export const ChatModeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isSuffixEnabled, setIsSuffixEnabled] = useState<boolean>(true);
  const [isBannerEnabled, setIsBannerEnabled] = useState<boolean>(false);
  const [adType, setAdType] = useState<ChatOption>(ChatOption.NO_ADS);

  return (
    <ChatModeContext.Provider
      value={{
        isSuffixEnabled,
        setIsSuffixEnabled,
        isBannerEnabled,
        setIsBannerEnabled,
        adType,
        setAdType,
      }}
    >
      {children}
    </ChatModeContext.Provider>
  );
};

export const useChatMode = () => {
  const context = useContext(ChatModeContext);

  if (!context) {
    throw new Error("useChatMode must be used within a ChatModeProvider");
  }
  return context;
};
