import React, { useState, useEffect } from "react";
import { Separator } from "~/shadcn/components/ui/separator";
import { useSearch } from "~/providers/SearchProvider";
import { Dialog } from "~/shadcn/components/ui/dialog";
import { SegmentedDialogContent } from "~/shadcn/components/ui/segmented-dialog";
import { Brain, Copy, Link2Icon, Mail } from "lucide-react";
import { toast } from "sonner";
import useComposeEmail from "~/queries/compose-email";
// @ts-ignore
import LinkedinIcon from "~/assets/images/linkedin.svg?react";
import { useApp } from "~/providers/AppProvider";
import { useQuery } from "@tanstack/react-query";
import { ToolbarActionButton } from "../ui/ToolbarActionButton";
import { EmailTemplateDropdown } from "./EmailTemplateDropdown";

const ActionFooter = ({
  toEmail,
  template,
}: {
  toEmail: string;
  template: string;
}) => {
  return (
    <div className="flex gap-4 bg-white rounded-lg shadow-lg border h-fit p-4">
      <ToolbarActionButton
        listenKey="c"
        onClick={() => {
          navigator.clipboard.writeText(toEmail);
          toast.success("Copied email to clipboard");
        }}
        icon={<Link2Icon className="w-4 h-4 text-blue-500" />}
        text="Copy email"
        shortcut="C"
      />
      <ToolbarActionButton
        listenKey="g"
        onClick={() => {
          window.open(
            `https://mail.google.com/mail/?view=cm&fs=1&to=${toEmail}&su=Introduction&body=${encodeURIComponent(
              template
            )}`,
            "_blank"
          );
        }}
        icon={<Mail className="w-4 h-4 text-red-500" />}
        text="Open Gmail"
        shortcut="G"
      />
      <div className="w-full" />
    </div>
  );
};

export const ExternalSearchDetailView = ({
  connector_name,
  connector_email,
  person,
  open,
  setOpen,
}: {
  connector_name: string;
  connector_email: string;
  person: any;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { user } = useApp();
  const primaryLabel = person?.name ? `${person?.name}` : person?.email;
  const secondaryLabel = person?.name ? `${person?.email}` : null;

  const [template, setTemplate] = useState("warm intro");
  const [email, setEmail] = useState("");
  const toEmail = template == "warm intro" ? connector_email : person?.email;
  const toName = template == "warm intro" ? connector_name : person?.name;

  const { mutate, isPending } = useComposeEmail();
  const { query } = useSearch();

  const { data: linkedinProfile, isLoading } = useQuery({
    queryKey: ["linkedin_profile", person?.email],
    queryFn: () =>
      fetch(`/linkedin_profile?name=${person.name}&email=${person.email}`).then(
        (res) => res.json()
      ),
  });

  useEffect(() => {
    if (!person) {
      return;
    }

    if (template) {
      generateEmail(template);
    }
  }, [person, template]);

  function copyEmail(email: string) {
    navigator.clipboard.writeText(email);
    toast.success("Copied email to clipboard");
  }

  function generateEmail(template: string) {
    mutate(
      {
        receiver_name: primaryLabel,
        query: query,
        connector_name: connector_name,
        template_name: template,
      },
      {
        onSuccess: (data) => {
          setEmail(data.content);
        },
      }
    );
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <SegmentedDialogContent className="bg-clear border-0 shadow-none w-[740px]">
        <div className="flex flex-col h-full w-full gap-4">
          <div className="flex flex-col gap-4 bg-white rounded-lg shadow-lg border w-full overflow-y-auto h-[600px]">
            <div className="flex flex-col items-start gap-1 px-4 pt-4">
              <div className="flex items-center gap-2">
                <div className="font-medium text-lg">{primaryLabel}</div>
                {linkedinProfile && (
                  <button
                    className="pr-4"
                    onClick={() => {
                      window.open(linkedinProfile["link"], "_blank");
                    }}
                  >
                    <LinkedinIcon className="w-5 h-5 text-blue-500" />
                  </button>
                )}
              </div>
              <button
                onClick={() => copyEmail(secondaryLabel)}
                className="focus:outline-none text-slate-500 hover:text-black transition-colors"
              >
                {secondaryLabel}
              </button>
            </div>

            <div className="flex flex-col gap-2 p-4 border border-purple-400/50 rounded-lg mx-4 bg-gradient-to-r from-indigo-500/20 via-purple-500/20 to-pink-500/20">
              <div className="flex items-center gap-2 text-slate-500">
                <Brain className="w-4 h-4" />
                <div className="font-meidum text-sm">Connection Insight</div>
              </div>
              <div className="text-black">{person?.explanation}</div>
            </div>

            <Separator />

            <div className="px-4 flex items-center gap-2 justify-between w-full">
              <EmailTemplateDropdown value={template} setValue={setTemplate} />

              <button
                className="text-xs text-slate-500 flex items-center gap-2 hover:text-black transition-colors font-medium"
                onClick={() => {
                  navigator.clipboard.writeText(email);
                  toast.success("Copied email to clipboard");
                }}
              >
                Copy
                <Copy className="w-4 h-4" />
              </button>
            </div>

            <div className="text-sm px-4 h-full transition-all duration-100">
              <div className="bg-slate-100 rounded-lg p-4 whitespace-pre-wrap transition-all duration-100 min-h-[100px]">
                <div className="text-slate-500 text-xs font-medium mb-4">
                  SAMPLE EMAIL
                </div>

                <div className="text-slate-700 items-start flex flex-col text-xs font-medium mb-4 gap-1">
                  <button
                    className="hover:text-black transition-colors"
                    onClick={() => copyEmail(user?.email ?? "")}
                  >
                    From: {`${user?.name} <${user?.email}>`}
                  </button>
                  <button
                    className="hover:text-black transition-colors"
                    onClick={() => copyEmail(toEmail)}
                  >
                    To: {`${toName} <${toEmail}>`}
                  </button>
                </div>

                <div className="h-[1px] w-full bg-slate-300 mb-4" />
                {email && !isPending ? (
                  email
                ) : (
                  <div className="flex flex-col gap-2 animate-pulse">
                    <div className="h-4 bg-slate-200 rounded w-3/4"></div>
                    <div className="h-4 bg-slate-200 rounded w-1/2"></div>
                    <div className="h-4 bg-slate-200 rounded w-1/3"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <ActionFooter toEmail={toEmail} template={email} />
      </SegmentedDialogContent>
    </Dialog>
  );
};
