import { useMutation } from "@tanstack/react-query";
import { queryClient } from "~/components/App";
import { csrfToken } from "~/utils/helpers";

interface CreateLinkVariables {
  title: string;
  is_global: boolean;
  allowed_topics: string[];
}

export default function useCreateLink(onSuccess: () => void) {
  return useMutation({
    mutationFn: async ({ title, is_global, allowed_topics }: CreateLinkVariables) => {
      const response = await fetch("/api/links", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({ title, is_global, allowed_topics }),
      });
      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["links"] });
      onSuccess();
    },
  });
}
