import React from "react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

// Init should happen on Rails load
if (typeof window !== "undefined" && process.env.NODE_ENV === "production") {
  posthog.init("phc_kShUh2PKohiak8LxrgG8ovKQIJiLhKwVyiQkYaXPKVv", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only",
    capture_pageview: false,
  });
}

export function PHProvider({ children }) {
  if (process.env.NODE_ENV === "production") {
    return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
  } else {
    return <>{children}</>;
  }
}
