import React, { useState, useRef, KeyboardEvent } from "react";
import { Button } from "~/shadcn/components/ui/button";
import { X } from "lucide-react";

export interface Pill {
  id: number;
  text: string;
}

export default function TokenInputTextfield({
  pills,
  setPills,
  disabled,
}: {
  pills: Pill[];
  setPills: (pills: Pill[]) => void;
  disabled?: boolean;
}) {
  const [inputValue, setInputValue] = useState("");
  const [nextId, setNextId] = useState(0);
  const inputRef = useRef<HTMLSpanElement>(null);

  const createPill = (text: string) => {
    const newPill = { id: nextId, text: text.trim() };
    setPills([...pills, newPill]);
    setNextId(nextId + 1);
    setInputValue("");
    if (inputRef.current) {
      inputRef.current.textContent = "";
    }
  };

  const removePill = (id: number) => {
    setPills(pills.filter((pill) => pill.id !== id));
  };

  const removeLastPill = () => {
    if (pills.length > 0) {
      const newPills = [...pills];
      newPills.pop();
      setPills(newPills);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === "Enter" && !event.shiftKey && inputValue.trim()) {
      event.preventDefault();
      createPill(inputValue);
    } else if (event.key === "Backspace" && inputValue === "") {
      event.preventDefault();
      removeLastPill();
    }
  };

  const handleInput = (event: React.FormEvent<HTMLSpanElement>) => {
    setInputValue(event.currentTarget.textContent || "");
  };

  return (
    <div className="w-full">
      <div
        className={`text-sm min-h-[100px] max-h-[300px] overflow-y-auto p-2 border border-input rounded-md focus-within:outline-none focus-within:ring-2 focus-within:ring-ring flex flex-wrap items-start content-start transition-colors ${
          disabled ? "bg-slate-100 cursor-not-allowed" : ""
        }`}
        onClick={() => inputRef.current?.focus()}
      >
        {pills.length === 0 && inputValue === "" && (
          <span className="text-muted-foreground text-sm">
            Type and press Enter to create a topic...
          </span>
        )}
        {pills.map((pill) => (
          <span
            key={pill.id}
            className="inline-flex items-center bg-primary text-primary-foreground px-2 py-1 rounded-full text-sm m-1"
          >
            {pill.text}
            <Button
              type="button"
              variant="ghost"
              size="icon"
              className="h-4 w-4 ml-1 hover:bg-primary-foreground hover:text-primary rounded-full"
              onClick={() => removePill(pill.id)}
            >
              <X className="h-3 w-3" />
            </Button>
          </span>
        ))}
        <span
          ref={inputRef}
          role="textbox"
          contentEditable={!disabled}
          onKeyDown={handleKeyDown}
          onInput={handleInput}
          className="inline-block min-w-[50px] focus:outline-none"
          style={{ lineHeight: "2em" }}
        />
      </div>
      <p className="mt-2 text-xs text-muted-foreground">
        Only queries matching these topics will be allowed.
      </p>
    </div>
  );
}
